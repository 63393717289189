//------------------------------------------
// Introduction Text
// ------------------------------------------
// .case-studies section{
//     margin: 5vw 0;
// }

.case-studies{
    .hero-header{
        margin-top: -28vw;
		text-transform: uppercase;
  		text-align: right;
		@at-root #{&} {
            @include phone-small-bp{
                margin-top: -35vw;
			}
		}
	}
    .bg-gif{
        margin-top: -15vw;
        opacity: 0.7;
        grid-column: 2/span 10;
        @at-root #{&} {
            @include phone-small-bp{
                grid-column: 1/span 1;
				padding: 10vw;
		box-sizing: border-box;
            }
        }
    }
}

//-------------------------------------------
// Project Info
// ------------------------------------------
// .project-details{
// 	grid-area: project-details;
// 	// padding-right: 3rem;
// 	display: grid;
// 	align-self: center;
// }
.project-description{
	grid-area: project-description;
	padding-right: 2rem;
	transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.3s;
}
.project-link{
	grid-area: project-link;

	transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.3s;
}
.project-number{
	grid-area: project-number;
	margin: 0;
	padding: 0;
	font-size: 2rem;
	transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.3s;
}
.project-container {
	@at-root #{&} {
		@include phone-small-bp {
			display: flex;
			flex-direction: column-reverse;
		}
		@include phone-large-bp {
			display: flex;
			flex-direction: column-reverse;
		}
		@include tablet-small-bp {
			display: grid;
		}
	}
	.grid-item-span-4{
		z-index: 10;
	}
}
//-------------------------------------------
// Video Section
// ------------------------------------------
.video-container {
	// z-index: 6;
	// overflow: hidden;
	position: relative;
	grid-area: video;
	height: 100%;
	grid-row-start: 1;
	grid-row-end: 6;
	width: 96%;
    -webkit-filter: grayscale(100%) contrast(150%) ;
    filter: grayscale(100%) contrast(150%) ;

	@at-root #{&} {
		@include phone-small-bp {
			// grid-row-end: 2;
			// grid-row-start: 3;
			width: 100%;
			height: 40vh;
			grid-row: 2  ;
		}
	}
}
// .overflow-hide{
// 	height: 100%;
// 	overflow: hidden;
// 	display: inline-block;
// }
.video-case-studies {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.6s;
	// overflow: hidden;
}
.hover-active-first,
.hover-active-second,
.hover-active-third {
	.video-case-studies{
		opacity: 1;
		// transform: scale(1.02);
	}
	.video-container{
		&:after{
			opacity: 0;
		}
        filter: grayscale(0%) contrast(100%) ;
    }
	.title-link{
		color: var(--white);
		-webkit-text-stroke: 2px white;
	    -webkit-text-fill-color: transparent;
	}
	.project-description{
		color: var(--white);
	}
    .project-number{
		color: var(--white);
	}
}
//-------------------------------------------
// Title
// ------------------------------------------
.title-link {
	grid-area: project-name;
	text-decoration: none;
	text-shadow: none;
	transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.3s;
	// transition: color ease-in 0.6s;
	color: var(--white);
	font-family: var(--neuepower-ultra);
	grid-column-end: 3;
	z-index: 20;
	line-height: 1;
	text-transform: uppercase;
	&:hover{
		// color: var(--pink);
	}
	@at-root #{&}{
		@include phone-small-bp{
			// font-size: 2.6rem;
			font-size: 8.4vw;
			// margin-top: 1rem;
			grid-row: 2 ;
			align-self: end;
		}
		@include phone-large-bp {
			font-size: 4.8rem;
		}
		@include tablet-small-bp {
			font-size: 7.2rem;
		}
		@include tablet-large-bp {
			font-size: 10.4rem;
		}
		@include desktop-small-bp {
			font-size: 12.2rem;
		}
		@include desktop-medium-bp {
			font-size: 14rem;
		}
		@include desktop-medium-large-bp {
			font-size: 21.5rem;
		}
		@include desktop-large-bp {
			font-size: 22rem;
		}
	}
	&:visited{
		color: var(--white);
	}
}
// --------------------------------------
// Background Effect
// --------------------------------------
// .bg-effect{
// 	overflow: visible;
// 	&::before{
// 		content: "";
// 		background-color:var(--white) ;
// 		// background-color:var(--aqua) ;
// 		position: absolute;
// 		width:83%;
// 		z-index: -1;
// 		left: 14%;
// 		height: calc(100% + 4rem);;
// 		top: -2rem;
// 		transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.6s;
// 		@at-root #{&}{
// 			@include phone-small-bp{
// 				// height: 38vh;
// 				height: calc(100% + 2rem);;
// 				top: -1rem;
// 				left: 26%;
// 				width: 66%;
// 			}
// 		}
// 	}
// 	&::after{
// 		background-color:var(--white) ;
// 		// background-color:var(--pink) ;
// 		content: "";
// 		height: 60%;
// 		left: 27%;
// 		position: absolute;
// 		top: 23%;
// 		width: calc(73% + 2rem);
// 		z-index: -2;
// 		transition: all  cubic-bezier(0.705, 0.000, 0.000, 1.220) 0.6s;
// 		@at-root #{&}{
// 			@include phone-small-bp{
// 				width: calc(73% + 1rem);
// 			}
// 		}
// 	}
// }
// .hover-active-first .video-container .bg-effect,
// .hover-active-second .video-container .bg-effect,
// .hover-active-third .video-container .bg-effect {
// 	&::after{
// 		transform: scale(.96);
// 		// width: calc(73% + 12rem);
// 	}
// 	&::before{
// 		transform: scale(.97);
// 	}
// }


