//======================================================
// Grids
//======================================================
.wrapper{
    margin: 0 12rem;
    @at-root #{&} {
        @include phone-small-bp{
            // margin:0 6rem;
            margin:0 4rem;
        }
        @include phone-large-bp {
            margin:0 7rem;
        }
        @include tablet-small-bp {
            margin: 0 8rem;
        }
        @include desktop-small-bp{
            margin: 0 12rem;
        }
        @include desktop-medium-bp{
            margin:0 14rem;
        }
        @include desktop-large-bp{
            margin: 0 16vw;
        }
    }
    // background: var(--charcoal-light);
}
// @include phone-small-bp{
//     :root{
//     --gap-value:2rem;
//     --padding-value:2rem;
//     }
// }
// @include phone-large-bp{
//     :root{
//     --gap-value:2rem;
//     --padding-value:2rem;
//     }
// }
// @include tablet-small-bp{
//     :root{
//         --gap-value:2rem;
//         --padding-value:2rem;
//     }
// }
// @include tablet-large-bp{
//     :root{
//         --gap-value:2rem;
//         --padding-value:2rem;
//     }
// }
// @include desktop-small-bp{
//     :root{
//         --gap-value:2rem;
//         --padding-value:2rem;
//     }
// }
// @include desktop-medium-bp{
//     :root{
//         --gap-value:2rem;
//         --padding-value:2rem;
//     }
// }
// @include desktop-large-bp{
//     :root{
//         --gap-value:2rem;
//         --padding-value:2rem;
//     }
// }

:root {
    --xgap-value:8rem;
    --xpadding-value:8rem;
}

@include phone-small-bp{
    :root{
    --gap-value:4rem;
    --padding-value:4rem 0rem;
    }
}
@include phone-large-bp{
    :root{
    --gap-value:4rem;
    --padding-value:4rem 0rem;
    }
}
@include tablet-small-bp{
    :root{
        --gap-value:4rem;
        --padding-value:4rem 0;
    }
}
@include tablet-large-bp{
    :root{
        --gap-value:6rem;
        --padding-value:6rem 0;
    }
}
@include desktop-small-bp{
    :root{
        --gap-value:6rem;
        --padding-value:6rem 1rem;
    }
}
@include desktop-medium-bp{
    :root{
        --gap-value:8rem;
        --padding-value:8rem 4rem;
    }
}
@include desktop-large-bp{
    :root{
        --gap-value:10rem;
        --padding-value:10rem;
    }
}


.grid-col-12{
    display: grid;
    gap: var(--gap-value);
    padding: var(--padding-value);
    @at-root #{&}{
        @include phone-small-bp {
            grid-template-columns: 1fr;
          
            .grid-item-span-2-offset,
            .grid-item-span-3-centre,
            .grid-item-span-3-offset,
            .grid-item-span-4-centre,
            .grid-item-span-4-offset,
            .grid-item-span-4-centre-solo,
            .grid-item-span-4-col-4-centre,
            .grid-item-span-5-centre-solo,
            .grid-item-span-5-centre,
            .grid-item-span-6-centre,
            .grid-item-span-8-centre,
            .grid-item-span-10-centre{
                grid-column: span 1;
            }
        }
        @include phone-large-bp {
            grid-template-columns: repeat(12, 1fr);
            .grid-item-span-2{
                grid-column: span 2;
            }
            .grid-item-span-3{
                grid-column: span 3;
            }
            .grid-item-span-4{
                grid-column: span 4;
            }
            .grid-item-span-2-centre{
                grid-column: 2 / span 3;
            }
            .grid-item-span-2-offset{
                grid-column: 2 / span 2;
            }
            .grid-item-span-3-centre{
                grid-column: 3 / span 2;
            }
            .grid-item-span-3-offset{
                grid-column: 2 / span 3;
            }
            .grid-item-span-4-centre{
                grid-column: 2 / span 4;
            }
            .grid-item-span-4-offset{
                grid-column: 3 / span 4;
            }
            .grid-item-span-4-centre-solo{
                grid-column: 5 / span 4;
            }
            .grid-item-span-4-col-4-centre{
                grid-column: 4 / span 6;
            }
            .row-span-2{
                grid-row: 1 / span 2;
            }
            .row-span-3{
                grid-row: 1 / span 3;
            }
            .grid-item-span-5{
                grid-column: span 5;
            }
            .grid-item-span-5-centre-solo{
                grid-column: 4 / span 5;
            }
            .grid-item-span-5-centre{
                grid-column: 2 / span 5;
            }
            .grid-item-span-6{
                grid-column: span 6;
            }
            .grid-item-span-6-centre{
                grid-column: 4 / span 6;
            }
            .grid-item-span-7{
                grid-column: span 7;
            }
            .grid-item-span-8{
                grid-column: span 8;
            }
            .grid-item-span-8-centre{
                grid-column: 3 / span 8;
            }
            .grid-item-span-9{
                grid-column: span 9;
            }
            .grid-item-span-10{
                grid-column: span 10;
            }
            .grid-item-span-10-centre{
                grid-column: 3 / span 8;
            }
            .grid-item-span-11{
                grid-column: span 11;
            }
            .grid-item-span-12{
                grid-column: span 12;
            }
            .grid-align-center{
                align-self:center;
            }
            .grid-align-end{
                align-self:end;
            }
            .grid-align-start{
                align-self:start;
            }
        }
    }
}
.justify-end{
    justify-self: end;
}


// .grid-item-span-2{
//     grid-column: span 2;
// }
// .grid-item-span-3{
//     grid-column: span 3;
// }
// .grid-item-span-4{
//     grid-column: span 4;
// }
// .grid-item-span-2-centre{
//     grid-column: 2 / span 3;
// }
// .grid-item-span-2-offset{
//     grid-column: 2 / span 2;
// }
// .grid-item-span-3-centre{
//     grid-column: 3 / span 2;
// }
// .grid-item-span-3-offset{
//     grid-column: 2 / span 3;
// }
// .grid-item-span-4-centre{
//     grid-column: 2 / span 4;
// }
// .grid-item-span-4-offset{
//     grid-column: 3 / span 4;
// }
// .grid-item-span-4-centre-solo{
//     grid-column: 5 / span 4;
// }
// .grid-item-span-4-col-4-centre{
//     grid-column: 4 / span 6;
// }
// .row-span-2{
//     grid-row: 1 / span 2;
// }
// .row-span-3{
//     grid-row: 1 / span 3;
// }
// .grid-item-span-5{
//     grid-column: span 5;
// }
// .grid-item-span-5-centre-solo{
//     grid-column: 4 / span 5;
// }
// .grid-item-span-5-centre{
//     grid-column: 2 / span 5;
// }
// .grid-item-span-6{
//     grid-column: span 6;
// }
// .grid-item-span-6-centre{
//     grid-column: 4 / span 6;
// }
// .grid-item-span-7{
//     grid-column: span 7;
// }
// .grid-item-span-8{
//     grid-column: span 8;
// }
// .grid-item-span-8-centre{
//     grid-column: 3 / span 8;
// }
// .grid-item-span-9{
//     grid-column: span 9;
// }
// .grid-item-span-10{
//     grid-column: span 10;
// }
// .grid-item-span-10-centre{
//     grid-column: 3 / span 8;
// }
// .grid-item-span-11{
//     grid-column: span 11;
// }
// .grid-item-span-12{
//     grid-column: span 12;
// }
// .grid-align-center{
//     align-self:center;
// }
// .grid-align-end{
//     align-self:end;
// }
// .grid-align-start{
//     align-self:start;
// }



.grid-row-1{
    grid-row-start: 1;
}
.grid-row-2{
    grid-row-start: 2;
}
.grid-row-3{
    grid-row-start: 3;
}
.grid-row-4{
    grid-row-start: 4;
}
.grid-row-5{
    grid-row-start: 5;
}
.grid-row-6{
    grid-row-start: 6;
}



//======================================================
// Flex Utility Classes
//======================================================
.flex {
    display: flex;
}

.flex-left{
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.flex-centre {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-centre-right {
    display: flex;
    justify-content:flex-end;
    align-items: center;
}
.flex-centre-left {
    display: flex;
    justify-content:flex-start;
    align-items: center;
}

.flex-centre-reverse {
    display: flex;
    align-items: center;
    justify-items:center;
    flex-direction: row-reverse;
}


.flex-col-3 {
    display: flex;
}

.flex-centre-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.flex-centre-col-left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
}
