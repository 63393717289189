.loader{
    position: fixed;
    z-index: 100001;
    width: 100vw;
    height: 100vh;
    background: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    flex-flow: column;
    border-bottom: .4rem solid var(--white);
    isolation: isolate;
    h2{
        font-size: 10rem;
        margin: 0;
        font-family: var(--neuepower-ultra);
        @at-root #{&}{
            @include phone-small-bp {
            font-size: 2rem;
            }
        }
    }
    img{
        opacity: 0.4;
        height: 80vh;
        object-fit: contain;
        width: 80vw;
        // border: 2px solid springgreen;
        z-index: 1;
        @at-root #{&}{
        @include phone-small-bp {
            // padding: 2rem;
            width: 90vw;
            height: 90vh;
        }
        }
    }
  }
  .centre-container{
    // position: absolute;
    // inset: 0;
    // margin: auto;
    display: grid;
    width: 16rem;
    padding: 2rem;
    height: 16rem;
    z-index: 2;
    text-align: center;
    border-radius: 0.2rem;
    @at-root #{&}{
        @include phone-small-bp {
            width: 11rem;
            height: 11rem;
        }
    }
  }
//-------------------------------------------
// Logo Glitch Effect
// ------------------------------------------
.svg-glitch-huge {
  @include svgGlitch("example-five", 30, #ffffff, transparent,#ffffff, #ffffff, 7, 7, 0,0);
  width: 8rem;
  height: 8rem;
  @at-root #{&}{
    @include phone-small-bp {
      width: 4rem;
      height: 4rem;
    }
  }
  svg{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    margin: auto;
  }
}

//-------------------------------------------
// Exit Animation
// ------------------------------------------
.loader.hidden{
    animation:  .66s ease-in-out fadeOut 0.3s;
    animation-fill-mode: forwards;
}
@keyframes fadeOut {
100%{
    transform: translateY(-100%);
    visibility: hidden;
}
}
.no-scroll{
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
.no-scroll.hidden{
    overflow-y: scroll !important;
    overflow-x: auto !important;
}


// .loading-screen .glitch-heading {
  
//   position: relative; }

// .glitch-heading:before, .glitch-heading:after {
//   content: attr(data-text);
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   background: #F8F8F8;
//   clip: rect(0, 0, 0, 0); }
//   .loading-screen .glitch-heading:after {
//   left: 6px;
//   text-shadow: -3px 0 red;
  
//   animation: glitch-heading-anim-1 2s infinite linear alternate-reverse; }
//   .loading-screen .glitch-heading:before {
//   left: -6px;
//   text-shadow: 6px 0 blue;
  
//   animation: glitch-heading-anim-2 3s infinite linear alternate-reverse; }


//   @keyframes glitch-heading-anim-2 {
//     0% {
//     clip: rect(21px, 850px, 42px, 0); }
//     5% {
//     clip: rect(92px, 850px, 77px, 0); }
//     10% {
//     clip: rect(95px, 850px, 112px, 0); }
//     15% {
//     clip: rect(81px, 850px, 109px, 0); }
//     20% {
//     clip: rect(44px, 850px, 63px, 0); }
//     25% {
//     clip: rect(78px, 850px, 57px, 0); }
//     30% {
//     clip: rect(102px, 850px, 36px, 0); }
//     35% {
//     clip: rect(21px, 850px, 23px, 0); }
//     40% {
//     clip: rect(108px, 850px, 48px, 0); }
//     45% {
//     clip: rect(18px, 850px, 15px, 0); }
//     50% {
//     clip: rect(50px, 850px, 47px, 0); }
//     55% {
//     clip: rect(18px, 850px, 44px, 0); }
//     60% {
//     clip: rect(88px, 850px, 58px, 0); }
//     65% {
//     clip: rect(64px, 850px, 80px, 0); }
//     70% {
//     clip: rect(27px, 850px, 30px, 0); }
//     75% {
//     clip: rect(20px, 850px, 56px, 0); }
//     80% {
//     clip: rect(82px, 850px, 47px, 0); }
//     85% {
//     clip: rect(83px, 850px, 104px, 0); }
//     90% {
//     clip: rect(91px, 850px, 38px, 0); }
//     95% {
//     clip: rect(89px, 850px, 106px, 0); }
//     100% {
//     clip: rect(109px, 850px, 101px, 0); } }

//     @keyframes glitch-heading-anim-1 {
//       0% {
//       clip: rect(74px, 850px, 47px, 0); }
//       5% {
//       clip: rect(1px, 850px, 10px, 0); }
//       10% {
//       clip: rect(2px, 850px, 53px, 0); }
//       15% {
//       clip: rect(83px, 850px, 95px, 0); }
//       20% {
//       clip: rect(77px, 850px, 10px, 0); }
//       25% {
//       clip: rect(26px, 850px, 13px, 0); }
//       30% {
//       clip: rect(112px, 850px, 72px, 0); }
//       35% {
//       clip: rect(89px, 850px, 47px, 0); }
//       40% {
//       clip: rect(101px, 850px, 76px, 0); }
//       45% {
//       clip: rect(15px, 850px, 6px, 0); }
//       50% {
//       clip: rect(75px, 850px, 29px, 0); }
//       55% {
//       clip: rect(21px, 850px, 17px, 0); }
//       60% {
//       clip: rect(90px, 850px, 113px, 0); }
//       65% {
//       clip: rect(46px, 850px, 11px, 0); }
//       70% {
//       clip: rect(105px, 850px, 88px, 0); }
//       75% {
//       clip: rect(45px, 850px, 41px, 0); }
//       80% {
//       clip: rect(60px, 850px, 104px, 0); }
//       85% {
//       clip: rect(52px, 850px, 20px, 0); }
//       90% {
//       clip: rect(56px, 850px, 42px, 0); }
//       95% {
//       clip: rect(91px, 850px, 9px, 0); }
//       100% {
//       clip: rect(94px, 850px, 63px, 0); } }