.about-page{
    .grid-row-3 h1{
        @at-root #{&} {
            @include phone-small-bp{
                margin-top: 17vw;
            }
        }
    }
    .bg-image{
        grid-column: 7 /span 6;
        grid-row: 1 / span 4;
        position: absolute;
        z-index: -1;
        @at-root #{&} {
            @include phone-small-bp{
                grid-column: span 1;
                padding: 8vw;
                box-sizing: border-box;
            }
        }
    }
    .grid-item-span-12,
    .grid-item-span-8-centre{
        @at-root #{&} {
            @include phone-small-bp{
                grid-column: span 1;
            }
        }
    }
    .justify-end {
        @at-root #{&} {
            @include phone-small-bp{
                // justify-self: start;
            }
        }
      }
    
}
