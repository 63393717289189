// ======================================================
// ---Main Menu
// 1. Menu Items
// 2. Menu Container
// 3. Video Hover
// 4. Hamburger Toggle
// 5. Side Navigation
// ======================================================

// ======================================================
// Menu Items
// ======================================================
.main-menu {
    list-style: none;
    z-index: 2003;
    margin: 0 10vw ;
    position: fixed;
    will-change: transform;
    transform:translate(-110vw,0) ;
    width: 100vw;
    height: 100vh;
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        padding: 0;
        margin: 0;
      }
    }
    a{
      font-size:9vw;
      font-family: var(--neuepower-ultra);
      padding: 0;
      line-height: 1;
      text-transform: uppercase;
      text-decoration: none;
      color: var(--white)!important;
      transition: all 0.1s linear;
      display: inline-block;
      &:hover{
        -webkit-text-stroke: 2px white;
        -webkit-text-fill-color: transparent!important; 
        transform:translate(1vw,0);
      }
      @at-root #{&} {
        @include phone-small-bp{
          font-size:8.2vw;
        }
      }
    }
    &.open{
      transform:translate(0,0) ;
    }
    &.flex-centre-left{
      @at-root #{&} {
        @include phone-small-bp{
          // align-items: start;
        }
      }
    }
  }

  // ------------------------------------------
  // Menu Items Animation
  // ------------------------------------------/
  .open{
    .menu-item{
      opacity: 0;
      transform:translate(0,0) scale(1);
      animation: 0.3s FadeIn 0.3s cubic-bezier(.14,.87,.92,.17);
      animation-fill-mode: both;
    }
    .menu-item:nth-child(1) {
      animation-delay: .2s
    }
    .menu-item:nth-child(2) {
      animation-delay: .3s
    }
    .menu-item:nth-child(3) {
      animation-delay: .4s
    }
    .menu-item:nth-child(4) {
      animation-delay: .5s
    }
  }
  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    85% {
      opacity: 0  ;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes FadeOut {
    0% {
      opacity: 1;
    }
    85% {
      opacity: 0  ;
    }
    100% {
      opacity: 0;
    }
  }
  // ======================================================
  // Menu Container
  // ======================================================
  .slide-in-right-container{
    transition: transform ease-in .4s;
    transform: translate(-100vw, 0) scale(1);
    position: fixed;
    // border-right:0.2vw solid var(--aqua);
    background: var(--black);
    width: 33.333%;
    height:  100vh;
    z-index: 2000;
    will-change: transform;
      &.open{
        transform:translate(0,0) ;
      }
  }
  .slide-in-left-container{
    transition: transform ease-in .4s;
    transform: translate(100vw,0) scale(1);
    position: fixed;
    // border-left:0.2vw solid var(--aqua);
    background: var(--black);
    width: 66.666%;
    height: 100%;
    z-index: 2002;
    left: 33.333%;
    will-change: transform; 
      &.open{
        transform:translate(0,0) ;
      }
  }
  // ======================================================
  // Video Hover Effect
  // ======================================================
  .video-responsive-menu{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    // opacity: 0.5;
    // filter: grayscale(100%) contrast(130%);
  }
  .video-background-container-first,
  .video-background-container-second,
  .video-background-container-third,
  .video-background-container-fourth{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    background-color: black;
  }
  // ------------------------------------------
  // Video Hover Effect Active
  // ------------------------------------------/
  .video-background-container-first{
    &.open{
    opacity: 1;
   }
  }
  .video-background-container-second{
    &.open{
    opacity: 1;
   }
  }
  .video-background-container-third{
    &.open{
    opacity: 1;
   }
  }
  .video-background-container-fourth{
    &.open{
    opacity: 1;
   }
  }

  // ======================================================
  // Hamburger toggle button
  // ======================================================
  .menu-toggle-container {
    position: fixed;
    bottom: 2.7rem;
    right: 2.7rem;
    z-index: 10000;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    user-select: none;
    @at-root #{&} {
      @include phone-small-bp{
        bottom: 1.5rem;
        right: 1.5rem;
      }
      @include desktop-large-bp{
        bottom: 5rem;
        right: 6rem;
      }
    }
  }
  .menu-hambuger {
    font-family: var(--neuepower-ultra);
    font-size: 1rem;
    letter-spacing: 0.15rem;
    line-height: 1rem;
    // color: var(--aqua);
    transition: transform 0.3s ease-in, color 0.1s ease-in 0.2s;
    &:hover {
      transform: rotate(45deg);
    }
    @at-root #{&} {
        @include  desktop-large-bp{
          font-size: 1.2rem;
          letter-spacing: 0.3rem;
          line-height: 1.2rem;
        }
      }
  }
  // ------------------------------------------
  // Hamburger Animation
  // ------------------------------------------/
  .top-bun,
  .bottom-bun {
    transform: rotate(0deg);
  }
  .menu-toggle-container.close {
    .menu-hambuger {
      letter-spacing: 0.37rem;
      transform-origin: 54% 77%;
      &:hover {
        transform-origin: 54% 77%;
        transform: rotate(-90deg);
      //  color: var(--aqua); 
      }
    }
    .top-bun {
      transform: rotate(45deg);
      transform-origin: 1.07rem 0.35rem;
    }
    .patty {
      display: none;
    }
    .bottom-bun {
      transform: rotate(-45deg);
      transform-origin: 1.65rem .9rem;
    }
  }
// ========================================================
// Side Navigation
// ========================================================
.page-navigation-container{
  position: relative;
}
.side-navigation{
  position: fixed;
  right: 1.4rem;
  height: calc(100vh - 6rem);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @at-root #{&}{
    @include phone-small-bp {
      right: 0.3rem;
    }
    @include phone-large-bp{
      // justify-content: start;
    }
    @include tablet-small-bp {}
    @include desktop-large-bp {
    }
  }
}
.page-navigation{
  ul{
    position: relative;
    padding: 0.4em 0;
    @at-root #{&}{
      @include phone-small-bp {
      }
      @include phone-large-only-bp{
      }
      @include desktop-large-bp {
      }
  }
  li{
    list-style: none;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    margin: 0.6rem 0;
    height: 2rem;
    isolation: isolate;
    // width: fit-content;
    @at-root #{&}{
      @include phone-small-bp {
        margin: 0.3rem 0;
        height: 1.8rem;
      }
      @include phone-large-bp{
        margin: 0.1rem 0;
        height: 1.6rem;
      }
      @include tablet-small-bp {
        margin: 0.6rem 0;
        height: 2rem;
      }
    }
  }
  }
 
  .active a, a:visited{
    transform: translateX(-0.8rem);
      &:hover {
      transform: translateX(-0.8rem);
    }
  }
  a, a:visited{
    padding-right: 1.4em;
    margin-right: -2rem;
    position: relative;
    color: var(--white);
    font-size: 1.6rem;
    font-family:var(var(--avenirnext-regular));
    text-decoration: none;
    text-align: left;
    letter-spacing: 0.05rem;
    z-index: 5;
    transition: all 0.2s cubic-bezier(.46, .17, .5, 1.05);
    &:hover {
      transform: translateX(-0.8rem)  scale(1),;
      color: var(--aqua);
    }
    
    @at-root #{&}{
      @include phone-small-bp {
        font-size: 1rem;
        letter-spacing: 0.01rem;
        margin-right: -1.5rem;
      }
      @include phone-large-bp{
        font-size: 1rem;
        letter-spacing: 0.01rem;
        margin-right: -1.8rem;
      }
      @include tablet-small-bp {
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        margin-right: -2rem;
      }
      @include desktop-large-bp {
        font-size: 2rem
      }
    }
  }
}
.hide-visually{
  visibility: hidden;
}
.page-navigation .active a{
  color: var(--aqua)!important;
}
// @media (hover:none) and (pointer: course){
//   a {
//     background-color: peachpuff
//   }
// }
@include phone-small-bp {
  .hide-mob{
    display: none;
  }
}
@include phone-large-bp {
  .hide-mob{
    display: none;
  }
}
@include tablet-small-bp {
  .hide-mob {
    display:block;
  }
  .show-mob{
    display: none;
  }
}
// ------------------------------------------
// Box Timeline Effect
// ------------------------------------------
.box-effect{
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;
  border: 0;
  padding: 0.01rem;
  background: var(--white);
  transition: all 0.1s ease-in-out;
  border-radius: 50%;
  display: block;
  z-index: 6;
}
.page-navigation-container .active .box-effect{
  background: var(--aqua);
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  z-index: 7;
  @at-root #{&}{
    @include phone-small-bp {
      height:0.8rem;
      width:0.8rem;
    }
    @include phone-large-bp{
      height:0.8rem;
      width:0.8rem;
    }
    @include tablet-small-bp {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
.box-effect-container{
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.5rem;
  z-index: 4;
}
.page-navigation-container li:hover .box-effect{
  height: 1.2rem;
  width: 1.2rem;
  background: var(--aqua);
  @at-root #{&}{
    @include phone-small-bp {
      height:0.8rem;
      width:0.8rem;
    }
    @include phone-large-bp{
      height:0.8rem;
      width:0.8rem;
    }
    @include tablet-small-bp {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
.page-navigation-container a.hovered .box-effect{
  height: 1.2rem;
  width: 1.2rem;
  @at-root #{&}{
    @include phone-small-bp {
      height:0.8rem;
      width:0.8rem;
    }
    @include phone-large-bp{
      height:0.8rem;
      width:0.8rem;
    }
    @include tablet-small-bp {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}

.page-navigation-container ul::after{
  content: ('');
  border-right: 0.2rem solid var(--white);
  position: absolute;
  height: 100%;
  top: 0;
  right: 0.4rem;
  z-index: -1;
  @at-root #{&}{
    @include phone-small-bp {
      right: 0.4rem;
      border-right: 0.18rem solid var(--white);
    }
    @include phone-large-bp {
      right: 0.4rem;
      border-right: 0.18rem solid var(--white);
    }
    @include tablet-small-bp {
      border-right: 0.2rem solid var(--white);
      right: 0.4rem;
    }
  }
}

@media (hover:none) {
  .page-navigation li a:hover{
    transform: translateX(0rem) skewX(0deg) scale(1),;
  }
  .page-navigation-container li:hover .box-effect{
    height: 0.6rem;
    width: 0.6rem;
  }
  .page-navigation-container li.active:hover .box-effect{
    height: 1.2rem;
    width: 1.2rem;
  }
}
