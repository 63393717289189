:root{
	--neuepower-ultra: 'NeuePower-Ultra';
	--neuepower-ultraoblique: 'NeuePower-UltraOblique';






	--avenir-heavyoblique: 'avenir-heavyOblique';
	--avenir-medium:  'Avenir-Medium';
	--avenir-mediumoblique: 'Avenir-MediumOblique';
	--avenirnext-regular:  'avenir-next-regular';
	--avenirnext-bold:  'Avenir_Next_Bold';



	--avenirnext-demibold:  'Avenir_Next_Demi_Bold';


}


@font-face {
	font-family: 'NeuePower-Ultra';
	src: url('../assets/fonts/neue-power/neue-power-ultra.eot');
	src: url('../assets/fonts/neue-power/neue-power-ultra.eot?#iefix') format('embedded-opentype'),
             url('../assets/fonts/neue-power/neue-power-ultra.woff2') format('woff2'),
	     url('../assets/fonts/neue-power/neue-power-ultra.woff') format('woff'),
	     url('../assets/fonts/neue-power/neue-power-ultra.ttf') format('truetype'),
	     url('../assets/fonts/neue-power/neue-power-ultra.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NeuePower-UltraOblique';
	src: url('../assets/fonts/neue-power/neue-power-ultra-oblique.eot');
	src: url('../assets/fonts/neue-power/neue-power-ultra-oblique.eot?#iefix') format('embedded-opentype'),
             url('../assets/fonts/neue-power/neue-power-ultra-oblique.woff2') format('woff2'),
	     url('../assets/fonts/neue-power/neue-power-ultra-oblique.woff') format('woff'),
	     url('../assets/fonts/neue-power/neue-power-ultra-oblique.ttf') format('truetype'),
	     url('../assets/fonts/neue-power/neue-power-ultra-oblique.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}



@font-face {
    font-family: 'Avenir-HeavyOblique';
    src: url('../assets/fonts/avenir/Avenir-HeavyOblique.eot');
    src:url('../assets/fonts/avenir/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/Avenir-HeavyOblique.woff2') format('woff2'),
        url('../assets/fonts/avenir/Avenir-HeavyOblique.woff') format('woff'),
        url('../assets/fonts/avenir/Avenir-HeavyOblique.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url('../assets/fonts/avenir/Avenir-Medium.eot');
    src:url('../assets/fonts/avenir/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/Avenir-Medium.woff2') format('woff2'),
        url('../assets/fonts/avenir/Avenir-Medium.woff') format('woff'),
        url('../assets/fonts/avenir/Avenir-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Avenir-MediumOblique';
    src: url('../assets/fonts/avenir/Avenir-MediumOblique.eot');
    src: url('../assets/fonts/avenir/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/Avenir-MediumOblique.woff2') format('woff2'),
        url('../assets/fonts/avenir/Avenir-MediumOblique.woff') format('woff'),
        url('../assets/fonts/avenir/Avenir-MediumOblique.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'avenir-next-regular';
    src: url('../assets/fonts/avenir/avenir-next-regular.eot');
    src: url('../assets/fonts/avenir/avenir-next-regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/avenir-next-regular.woff2') format('woff2'),
        url('../assets/fonts/avenir/avenir-next-regular.woff') format('woff'),
        url('../assets/fonts/avenir/avenir-next-regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Avenir_Next_Bold';
    src: url('../assets/fonts/avenir/Avenir_Next_Bold.eot');
    src: url('../assets/fonts/avenir/Avenir_Next_Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/Avenir_Next_Bold.woff2') format('woff2'),
        url('../assets/fonts/avenir/Avenir_Next_Bold.woff') format('woff'),
        url('../assets/fonts/avenir/Avenir_Next_Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Avenir_Next_Demi_Bold';
    src: url('../assets/fonts/avenir/Avenir_Next_Demi_Bold.eot');
    src: url('../assets/fonts/avenir/Avenir_Next_Demi_Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/avenir/Avenir_Next_Demi_Bold.woff2') format('woff2'),
        url('../assets/fonts/avenir/Avenir_Next_Demi_Bold.woff') format('woff'),
        url('../assets/fonts/avenir/Avenir_Next_Demi_Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

