button, a.btn{
    background: none;
    border: .1rem solid var(--white);
    border-radius: 4rem;
    color: #fff;
    color: var(--white)!important;
    cursor: pointer;
    display: inline-block;
    font-size: 2.4rem;
    padding: 2rem 5rem  2.5rem 5rem;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    transition: all 0.2s linear;
    &:hover{
        color: var(--black)!important;
        background: var(--white);
    }
    a:visited{
      color: var(--white);
    }
    @at-root #{&}{
		@include phone-small-bp{
                  font-size: 1.6rem;
                  padding: 1.6rem 3rem 1.8rem 3rem;
		}
		@include phone-large-bp {
			font-size: 1.6rem;
                  padding: 1.6rem 3rem 1.8rem 3rem;
		}
		@include tablet-small-bp {
                  font-size: 1.6rem;
                  padding: 1.6rem 3rem 1.8rem 3rem;
		}
            @include tablet-large-bp {
                  font-size: 2.2rem;
                  padding: 2rem 4.3rem 2.5rem;
            }
		@include desktop-large-bp {
                  font-size: 2.4rem;
                  padding: 2rem 5rem  2.5rem 5rem;
		}
	}
}

