// ======================================================
// ---Project pages
// 1. Project Title Video Mask
// 2. Project Spec
// 3. Paragraph Styling
// 4. Hero
// 5. Problem Solution
// 6. Images
// 7. Videos
// 8. Final Design
// 9.Tweets
// 10. Text Reflection
// 11. Emoji Background 
// ======================================================

// ======================================================
// Project Title Video Mask
// ======================================================
.project-intro-container{
    display: grid;
    grid-template-columns: 1fr;
    isolation: isolate;
    align-items: center;
    justify-items: center;
    // height: 100vh; 
    @at-root #{&}{
        @include phone-small-bp{
            // height: 50vh;
        }
    }
}

@keyframes scale-in-out {
    0%{
        transform: scale(.2);
    }
    0%{
        transform: scale(.2);
    }
    50%{
        transform: scale(1);
    }
    75%{
        transform: scale(1);
        // opacity: 1;
    }
    // 85%{
    //     transform: scale(1);
    //     opacity: 0;
    // }
    100%{
        transform: scale(0);
        // opacity: 0;
    }
}
.video-mask {
    grid-row: 1 / 2 ;
    grid-column: 1 / 2;
    mask: url(../assets/images/projectheadline7svg.svg);
    mask-repeat: no-repeat;
    min-height: 100vh;
    mask-position:50% 50%;
    width: 100%;
    z-index: 2;
    @at-root #{&}{
        @include phone-small-bp{
            mask: url(../assets/images/projectheadline8.svg);
            mask-repeat: no-repeat;
            mask-position:50% 50%;
            // height: 50vh;
        }
    }
}

#video-background {
    width: 100%;
    min-height: 100vh;
    object-position: center;
    object-fit: cover;
    grid-row: 1 / 2 ;
    grid-column: 1 / 2;
    z-index: 1;
    @at-root #{&}{
        @include phone-small-bp{
            // min-height: 20rem;
            // height: 50vh;
        }
    }
}

// ======================================================
// Project spec
// ======================================================
.project-spec-container {
    grid-gap: 1rem;
    column-gap: 0rem;
    grid-column-gap: 4rem;
    align-items: center;
    display: grid;
    justify-items: stretch;
    h2 {
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.8rem;
            }
            @include phone-large-bp {
                font-size: 1.8rem;
            }
            @include tablet-small-bp {
                font-size: 2.2rem;
            }
            @include desktop-large-bp {
                font-size: 2.8rem;
            }
        }
    }
    li {
        list-style-type: none;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.4rem;
            }
            @include phone-large-bp {
                font-size: 1.4rem;
            }
            @include tablet-small-bp {
                font-size: 1.6rem;
            }
            @include tablet-large-bp {
                font-size: 1.8rem;
            }
            @include desktop-large-bp {
                font-size: 2.2rem;
            }
        }
    }
    h1{
        text-align: center;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 4.2rem;
            }
            @include phone-large-bp {
                font-size: 8rem;
            }
            @include tablet-small-bp {
                font-size: 8rem;
            }
            @include tablet-large-bp {
                font-size: 10rem;
            }
            @include desktop-small-bp {
                font-size: 15rem;
            }
            @include desktop-medium-bp {
                font-size: 17rem;
            }
            @include desktop-large-bp {
                font-size: 24rem;
            }
        }
    }
    p{
        font-size: 4rem;
        font-style: italic;
        text-align: center;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.6rem;
            }
            @include phone-large-bp {
                font-size: 2.4rem;
            }
            @include tablet-small-bp {
                font-size: 2.4rem;
            }
            @include tablet-large-bp {
                font-size: 2.2rem;
            }
            @include desktop-small-bp {
                font-size: 2.4rem;
            }
            @include desktop-medium-bp {
                font-size: 4rem;
            }
            @include desktop-large-bp {
                font-size: 6rem;
            }
        }
    }
}
.project-info{
    display: grid;
    align-content: center;
    gap: 3rem;
    align-items: start;
    justify-content: center;
    li{
    }
    @at-root #{&}{
        @include phone-small-bp{
            grid-template-areas:
            "project-info-role  project-info-tools"
            "project-info-timeframe project-info-tools"
            "project-info-team project-info-team";
            gap: 0rem;
        }
        @include phone-large-bp {
            grid-template-areas: 
            "project-info-role  project-info-tools"
            "project-info-timeframe project-info-tools"
            "project-info-team project-info-team";
            gap: 3rem;
        }
        @include tablet-small-bp {
        }
        @include tablet-large-bp {
            grid-template-areas: 
            "project-info-role project-info-role project-info-tools project-info-team"
            "project-info-timeframe project-info-timeframe project-info-tools project-info-team";
            gap: 0rem 2rem;
        }
        @include desktop-small-bp {
            grid-template-areas: 
            "project-info-role project-info-timeframe project-info-tools project-info-team";
            gap: 0rem 4rem;
        }
        @include desktop-medium-bp {
        }
        @include desktop-large-bp {
            gap: 0rem 6rem;
        }
    }
}

.project-info-role {
    grid-area:project-info-role;
}
.project-info-timeline {
    grid-area:project-info-timeline ;
}
.project-info-tools {
    grid-area:project-info-tools ;
}
.project-info-team {
    grid-area:project-info-team ;
    .italic{
        // color: var(--aqua);
       
    }
}

// ======================================================
// Paragraph Styling
// ======================================================
.content {
    padding:4rem;
    background-color:var(--charcoal);
    border-left: 0.2rem solid var(--white);
    width: fit-content;
    @at-root #{&}{
        @include phone-small-bp{
            padding: 2rem;
            border-left: 0.18rem solid var(--white);
        }
        @include phone-large-bp {
            padding: 2rem;
            border-left: 0.18rem solid var(--white);
        }
        @include tablet-small-bp {
            padding: 4rem;
            border-left: 0.2rem solid var(--white);
        }
        @include tablet-large-bp {
            padding: 4rem;
        }
        @include desktop-small-bp {
            padding: 4rem;
        }
        @include desktop-medium-bp {
            padding: 4rem;
        }
        @include desktop-large-bp {
            padding: 6rem;
        }
    }
    p{
        max-width: 65ch;
    }
   li {
        max-width: 62ch;
    }
}

.news-story-container{
    column-count: 2;
}
//-------------------------------------------
// Vertical text
// ------------------------------------------
.brief-section{
   .content {
    position: relative;
   }
}

section.brief{
    @at-root #{&}{
        @include phone-small-bp{
            .hide-mob{
                display: none;
            }
            .show-mob {
                display:flex;
            }
        }
        @include phone-large-bp{
            .hide-mob{
                display: block;
            }
            .show-mob {
                display: none;
            }
        }
    }
}

.horizontal-heading{
    transform: rotate(180deg);
    position: absolute;
    top: 0rem;
    writing-mode: tb-rl;
    @at-root #{&}{
        @include phone-large-bp{
            font-size: 7rem;
            left: -6.4rem;
        }
        @include tablet-small-bp{
            font-size: 10rem;
            left: -9.2rem;
        }
        @include tablet-large-bp{
            font-size: 15.7rem;
            left: -14.5rem;
        }
        @include desktop-small-bp{
            font-size: 18rem;
            left: -16.6rem;
        }
        @include desktop-medium-bp{
            font-size: 25rem;
            left: -23rem;
        }
        @include desktop-large-bp{
            font-size: 33rem;
            left: -29.7rem;
        }
    }
}
.extra-centre{
    text-align: center;
    width: 100%;
}
//-------------------------------------------
// Big First Letter
// ------------------------------------------
.firstcharacter {
    // color: var(--aqua);
 
    float: left;
    font-size: 7.8rem;
    line-height: 4.2rem;
    padding-left: .25rem;
    padding-right: .5rem;
    padding-top: 1.2rem;
    @at-root #{&}{
		@include phone-small-bp{
            font-size: 6.3rem;
            line-height: 2.7rem;
		}
		@include phone-large-bp {
			font-size: 6.3rem;
            line-height: 2.7rem;
		}
		@include tablet-small-bp {
            font-size: 7.8rem;
            line-height: 4.2rem;
		}
		@include desktop-large-bp {
            font-size: 10.6rem;
            line-height: 6.4rem;
		}
	}
}
.content ul {
    margin-left: 1.5rem;
    @at-root #{&}{
		@include phone-small-bp{
			font-size: 1.4rem;
		}
		@include phone-large-bp {
			font-size: 1.4rem;
		}
		@include tablet-small-bp {
			font-size: 1.8rem;
		}
		@include desktop-large-bp {
            font-size: 2.4rem;
		}
	}
}
//-------------------------------------------
// Drake Mirror Style
// ------------------------------------------
.drake-style {
    font-size: 9.2vw; 
}
.mirror {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
// // ======================================================
// // Hero Section
// // ======================================================
 .hero-section {
     isolation: isolate;
     display: grid;
     position: relative;
     grid-template-columns: repeat(10, 1fr);
     grid-template-rows: auto auto;
     &::before{
         content: "";
         background-color:var(--charcoal);
         width: 0;
         width: 100%;
         height: 90vh;
         z-index: 2;
         grid-column: 2/ -1;
         align-self: center;
         grid-row: 1/ 3;
         width: 80%;
         transform: translate(-4%, 0);
         will-change:transform;
         @at-root #{&}{
             @include phone-small-bp{
                 height: 45vh;
             }
         }
     }
     &::after{
         background: var(--white);
         width: 87%;
         height: 40vh;
         content: "";
         z-index: 1;
         grid-column: 1/ 10;
         align-self: center;
         grid-row: 1/ 3;
         transform: translate(0, -50%);
         will-change:transform;
         @at-root #{&}{
             @include phone-small-bp{
                 height: 20vh;
             }
         }
     }
 }
 .hero-title{
     font-size: 12.6vw;
     color: var(--white);
    //  text-shadow: 0.2vw 0.2vw 0px  var(--black);
     grid-column: 2 / -1;
     grid-column: 10 / span 9;
     grid-row: 1/ 3;
     z-index: 5;
     align-self: center;
     justify-self: end;
     padding-right: 4rem;
     opacity: 0;
     will-change:transform;
     text-transform: uppercase;
     &.large{
         font-size: 16vw;
     }
     @at-root #{&}{
         @include phone-small-bp{
             padding-right: 1rem;
             font-size: 10vw;
         }
         @include phone-large-bp {
             padding-right: 1rem;
             font-size: 11vw;
         }
         @include tablet-small-bp {
             padding-right: 1rem;
             font-size: 11vw;
         }
         @include tablet-large-bp {
             padding-right: 4rem;
             font-size: 10.2vw;
         }
     }
 }
 .hero-image{
     width: 100%;
     aspect-ratio: 1 / 1;
     object-fit: cover;
     height: 110vh;
     grid-column:1 / 8;
     grid-row: 1 / 2;
     z-index: 3;
     align-self: center;
     padding-left: 4rem;
     transform: translate(50%, 0);
     width:70%;
     will-change:transform;
     @at-root #{&}{
         @include phone-small-bp{
             height: 55vh;
             grid-column: 1/9;
         }
     }
 }
 .hero-image-static-effect{
     width: 100%;
     aspect-ratio: 1 / 1;
     object-fit: cover;
     height: 110vh;
     grid-column:1 / 8;
     grid-row: 1 / 2;
     z-index: 3;
     align-self: center;
     padding-left: 4rem;
     transform: translate(50%, 0);
     width:70%;
     will-change:transform;
     @at-root #{&}{
         @include phone-small-bp{
             height: 55vh;
             grid-column: 1/9;
         }
     }
     &:after{
 		content: "";
 		position: absolute;
 		top: 0;
 		right: 0;
         grid-column:1 / 8;
         grid-row: 1 / 2;
 		z-index: 14;
 		width: calc(100% - 4rem);
 		height: 110vh;
         padding-left: 4rem;
 		background-image: url('../assets/images/noise-4.png');
 		margin: 0;
 		animation: noise .8s steps(10) infinite;
 		 transition: all ease-in 0.3s;
         opacity: .35;
         @at-root #{&}{
             @include phone-small-bp{
                 height: 55vh;
                 grid-column: 1/9;
             }
         }
 	}
 }
// ------------------------------------------
// Expanding Animation
// ------------------------------------------
 .fp-completely  .hero-title{
     animation: title-slide  .4s .2s cubic-bezier(0.705, 0.000, 0.000, 1.220) forwards;
 }
 @keyframes title-slide {
     0%{
         opacity: 0;
         transform: translate(-10vh 0);
     }
     50%{
         opacity: 1;
     }
     100%{
         opacity: 1;
         transform: translate(0 0);
     }
 }
 .fp-completely .hero-image-static-effect,
 .fp-completely .hero-image{
     animation: expand-image-bg  .4s cubic-bezier(0.705, 0.000, 0.000, 1.220) forwards;
 }
 @keyframes expand-image-bg {
     from{
         transform: translate(50%, 0px);
         width:70%
     }
     to{
         transform: translate(0%, 0px);
         width: 100%;
     }

 }
 .fp-completely .hero-section::before{
     animation: expand-pink-bg  .4s cubic-bezier(0.705, 0.000, 0.000, 1.220) forwards;
 }
 @keyframes expand-pink-bg {
     from{
         transform: translate(-4%, 0);
         width: 80%;
     }
     to{
         transform: translate(0%, 0);
         width: 100%;
     }
 }
 .fp-completely .hero-section::after{
     animation: expand-aqua-bg  .4s cubic-bezier(0.705, 0.000, 0.000, 1.220) forwards;
 }

 @keyframes expand-aqua-bg {
     from{
         transform: translate(0, -50%);
     }
     to{
         transform: translate(0, 0%);
     }
 }

 //======================================================
// Problem Solution
// ======================================================
.problem-solution-container{
    display: grid;
    grid-template-columns: 50% 0.4rem 50%;
    // transform: skewX(-8deg);
    @at-root #{&}{
        @include phone-small-bp{
            padding: 1rem 0;
        }
        @include phone-large-bp {
            padding: 2rem 0;
        }
        @include tablet-small-bp {
            padding: 6rem 0;
        }
        @include desktop-medium-bp{
            padding: 8rem 0;
        }
        @include desktop-large-bp {
            padding: 12rem 0;
        }
    }
    h2 {
        font-size: 7rem;
        margin: 0;
        font-family: var(--neuepower-ultraoblique);
        padding:0 1rem;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 2.8rem;
                padding:0 1rem;
            }
            @include phone-large-bp {
                font-size: 2.8rem;
                padding:0 1rem;
            }
            @include tablet-small-bp {
                font-size: 6rem;
                padding:0 3rem;
            }
            @include desktop-medium-bp{
                font-size: 8rem;
            }
            @include desktop-large-bp {
                font-size: 10rem;
                padding:0 4rem;
            }
        }
    }
    h4{
        font-size: 3rem;
        font-family: var(--avenir-mediumoblique);
        line-height: 1;
        padding-bottom: 2.5rem;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.2rem;
                padding:0 1rem 1rem;
            }
            @include phone-large-bp {
                font-size: 1.6rem;
                padding:0 1rem 1rem;
            }
            @include tablet-small-bp {
                font-size: 2.4rem;
                padding:0 3rem 3rem;
            }
            @include desktop-medium-bp{
                font-size: 3rem 3rem;
            }
            @include desktop-large-bp {
                font-size: 4.6rem;
                padding:0 4rem 4rem;
            }
        }
    }
}
.angled-border{
    transform: skewX(-7deg);
    @at-root #{&}{
        @include phone-small-bp{
            border-right: .2rem solid var(--white);
        }
        @include phone-large-bp {
            border-right: .2rem solid var(--white);
        }
        @include tablet-small-bp {
            border-right: .4rem solid var(--white);
        }
    }
}
.section-left{
    text-align: right;
    grid-gap: 0rem;
    column-gap: 0rem;
    grid-column-gap: 0rem;
    display: grid;
    align-content: start;
}
.section-right{
    text-align: left;
    grid-gap: 0rem;
    column-gap: 0rem;
    grid-column-gap: 0rem;
    display: grid;
    align-content: start;
}
.extra-margin-top{
    @at-root #{&}{
        @include phone-small-bp{
            margin-top: 1rem;
        }
        @include phone-large-bp {
            margin-top: 2rem;
        }
        @include tablet-small-bp {
            margin-top: 6rem;
        }
        @include desktop-medium-bp{
            margin-top: 8rem;
        }
        @include desktop-large-bp {
            margin-top: 12rem;
        }
    }
}
.extra-margin-bottom{
    margin-bottom: 6rem;
}
// ======================================================
// Images
// ======================================================
figure {
    margin: 0;
    position: relative;
}
.fig-title {
    font-size: 2.2rem;
    margin: 1.2rem 0 1.2rem;
    margin: 1.2rem 0 0 0;
    font-family:var(--avenirnext-bold);
    position: relative;
    @at-root #{&}{
        @include phone-small-bp {
            font-size: 1.6rem;
            margin: .6rem 0 0 0;
        }
        @include phone-large-bp{
            font-size: 1.6rem;
            margin: 0.6rem 0 0 0;
        }
        @include tablet-small-bp{
            font-size: 1.8rem;
            margin: 1.2rem 0 0 0;
        }
        @include desktop-small-bp{
            font-size: 2.2rem;
        }
        @include desktop-large-bp{
            font-size: 2.8rem;
        }
    }
}
.fig-link,
.fig-subtitle {
    font-family:var(--avenir-mediumoblique);
    font-size: 1.4rem;
    margin: 1.2rem 0 0 0;
    @at-root #{&}{
        @include phone-small-bp {
            font-size: 1.2rem;
            margin: 0.6rem 0 0 0;
        }
        @include phone-large-bp{
            font-size: 1.2rem;
            margin: 0.6rem 0 0 0;
        }
        @include tablet-small-bp{
            font-size: 1.4rem;
            margin: 1.2rem 0 0 0;
        }
        @include desktop-large-bp{
            font-size: 1.6rem;
        }
    }
 }
.fig-link {
    font-style: normal;
 }
.fig-title::after {
    content: '_____';
    font-size: 1rem;
    display: block;
}
// ======================================================
// Videos
// ======================================================
.video-responsive {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 80vh;

}
.video-portrait{
    .video-responsive{
        background-color:var(--charcoal);
        max-height: 90vh;
        aspect-ratio: 1 / 1;
        width: 100%;
        object-fit: contain;
    }
}


// ------------------------------------------
// Responsive
// ------------------------------------------
.video-responsive-large {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: left;
}
// ======================================================
// Final Design
// ======================================================
 .final-design-section{
     display: flex;
     @at-root #{&}{
         @include phone-small-bp{
              margin-top: -6rem;
         }
         @include phone-large-bp {
             margin-top: -6rem;
         }
         @include tablet-small-bp {
             margin-top: -6rem;
         }
         @include desktop-medium-bp{
             margin-top: -8rem;
         }
         @include desktop-large-bp {
             margin-top: -14rem;
         }
     }
     .horizontal-title{
         margin-right: -2.4vw;
         transform: rotate(180deg);
         font-size: 9vw;
         writing-mode: tb-rl;
     }
 }
 .text-design-gif {
     background:url(/assets/images/3-years-in-3-seconds.gif) center;
     background-clip: text;
     color: transparent;
     background-size:contain ;
     font-size:13vw;
     line-height: .72;
     text-transform: uppercase;
     display: grid;
     letter-spacing: 0.53vw;
     padding: 2vw;
     opacity: 0.9;
     background-color: #fff;
     font-weight: bold;
 }

 .expanded-bg{
 background-color: var(--aqua);
 width: calc(100% - 24em);
 h1{
      margin-top: -10rem;
 }
 .fig-title{
     color: pink;
 }
 &:after{
     background-color: var(--aqua);
     width: calc(100% - 24em);
     content: '';
     height: 100%;
     position: absolute;
 }
 }
 .coloured-bg{
     background: var(--charcoal);
 }
   .break-word{
      letter-spacing: -0.45vw;
     .extra-letter-spacing{
       }
   }

// ------------------------------------------
// Before & After
// ------------------------------------------
 .before-after-section {
     display: grid;
     align-content: baseline;
     h2{
        //  font-style: italic;
         text-align: center;
          font-family: 400;
    //      @at-root #{&}{
    //          @include phone-small-bp{
    //              margin-top: -1.92rem;
    //          }
    //          @include phone-large-bp {
    //              margin-top: -2.4rem;
    //          }
    //          @include tablet-small-bp {
    //              margin-top: -4rem;
    //          }
    //          @include tablet-large-bp {
    //              margin-top: -5.6rem;
    //          }
    //          @include desktop-small-bp {
    //              margin-top: -6.4rem;
    //          }
    //          @include desktop-medium-bp {
    //              margin-top: -8rem;
    //          }
    //          @include desktop-large-bp {
    //              margin-top: -11.2rem;
    //          }
    //      }
     }
 }

// ======================================================
// Tweets
// ======================================================

.tweet-container{
    .fig-title{
        font-style: italic;
    
        @at-root #{&}{
            @include phone-small-bp {
                font-size: 1.3rem;
            }
            @include phone-large-bp{
                font-size: 1.4rem;
            }
            @include tablet-small-bp{
                font-size: 1.8rem;
            }
            @include desktop-large-bp{
                font-size: 2.2rem;
            }
        }
    }
}
.img-hack{
    width: 100%!important;
}
.quote{
    // font-weight: bold;
        // color: var(--pink);
}
// ======================================================
// Text Reflection
// ======================================================
.text-reflect, .text-reflect:before, .text-reflect{
    display: block;
    margin: 0;
    padding: 0;
  }
.text-reflect, .text-reflect:before, .text-reflect:after {
    position: relative;
}
h1.text-reflect, h2.text-reflect {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.text-reflect:before {
    content: 'Reflections';
    top: 25.15vw;
    transform: scale(1, -1);
    backface-visibility: visible;
    z-index: -1;
    background: linear-gradient(180deg, rgba(30,32,34,1) 0%, rgb(255, 255, 255) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.4;
    height: 14vw;
    width: 100%;
}
// ======================================================
// // Outcomes Fireworks
// // ======================================================
 .tldr .italic,
 .outcomes .italic{
    font-size: 4rem;
    font-style: italic;
    text-align: center;
    line-height: 0.8;
    @at-root #{&}{
        @include phone-small-bp{
            font-size: 1.6rem;
        }
        @include phone-large-bp {
            font-size: 2.4rem;
        }
        @include tablet-small-bp {
            font-size: 2.4rem;
        }
        @include tablet-large-bp {
            font-size: 2.2rem;
        }
        @include desktop-small-bp {
            font-size: 6rem;
        }
        @include desktop-medium-bp {
            font-size: 6rem;
        }
        @include desktop-large-bp {
            font-size: 8rem;
        }
    }
}
 .emoji-title{
     font-style: normal;
 }

 .outcomes-container{
     z-index: 3;
     position: relative;
    //  height: 40vh;
     display: grid;
     align-content: center;
     @at-root #{&}{
         @include phone-small-bp{
             height: 20vh;
             align-content: end;
         }
     }
 }
 .outcomes-content-container{
     z-index: 3;
     position: relative;
 }

 .fireworks-container{
     overflow: hidden;
     z-index: 1;
     position: absolute;
     height: 60vh;
     width: 100%;
     top: -5vw;
     @at-root #{&}{
         @include phone-small-bp{
             height: 40vh;
         }
         @include phone-large-bp {
         }
         @include tablet-small-bp {
         }
         @include tablet-large-bp {
         }
         @include desktop-small-bp {
         }
         @include desktop-medium-bp {
         }
         @include desktop-large-bp {
         }
     }
 }
 .fireworks > .before, .fireworks > .after {
     position: absolute;
     z-index: 1;
     width: 5px;
     height: 5px;
     border-radius: 50%;
     box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
     animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }
  
   .fireworks > .after {
     animation-delay: 1.25s, 1.25s, 1.25s;
     animation-duration: 1.25s, 1.25s, 6.25s; 
 }
   @keyframes bang {
     from {
       box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; 
     } 
 }
   @keyframes gravity {
     to {
       transform: translateY(200px);
       -moz-transform: translateY(200px);
       -webkit-transform: translateY(200px);
       -o-transform: translateY(200px);
       -ms-transform: translateY(200px);
       opacity: 0; 
     } 
 }
   @keyframes position {
     0%, 19.9% {
       margin-top: 10%;
       margin-left: 40%;
     }
     20%, 39.9% {
       margin-top: 40%;
       margin-left: 30%;
     }
     40%, 59.9% {
       margin-top: 20%;
       margin-left: 70%;
     }
     60%, 79.9% {
       margin-top: 30%;
       margin-left: 20%;
     }
     80%, 99.9% {
       margin-top: 30%;
       margin-left: 80%;
     }
 }

// ======================================================
// Quote List
// ======================================================
.quote-list-container{
 display: grid;
 grid-template-columns: 1fr;
 gap: 12rem;
 margin-top: 6.4rem;
 @at-root #{&}{
        @include phone-small-bp{
            padding: 0.5rem;
        }
        @include phone-large-bp {
        }
        @include tablet-small-bp {
        }
        @include tablet-large-bp {
        }
        @include desktop-small-bp {
        }
        @include desktop-medium-bp {
            padding: 12rem;
        }
        @include desktop-large-bp {
        }
    }
}
.quote-container{
    blockquote{
        padding: 0;
        margin: 0;
    }
    .fig-quote {
        font-size: 3.8rem;
        font-family: var(--avenir-heavyoblique);
        // font-family: var(--neuepower-ultraoblique);
        text-align: center;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 2.4rem;
            }
            @include phone-large-bp {
            }
            @include tablet-small-bp {
            }
            @include tablet-large-bp {
            }
            @include desktop-small-bp {
            }
            @include desktop-medium-bp {
            }
            @include desktop-large-bp {
            }
        }
      }
    .caption{
        display: grid;
        justify-content: center;
        margin-top: 2rem;
    }
    .fig-author{
        // color: var(--aqua);
        font-size: 3rem;
        font-family: var(--avenirnext-bold);
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 2.4rem;
            }
            @include phone-large-bp {
            }
            @include tablet-small-bp {
            }
            @include tablet-large-bp {
            }
            @include desktop-small-bp {
            }
            @include desktop-medium-bp {
            }
            @include desktop-large-bp {
            }
        }
    }
    .fig-job-title{
        // font-family:;
        margin-top: 0.2rem;
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.4rem;
            }
            @include phone-large-bp {
                font-size: 1.4rem;
            }
            @include tablet-small-bp {
            }
            @include tablet-large-bp {
            }
            @include desktop-small-bp {
            }
            @include desktop-medium-bp {
                font-size: 1.4rem;
            }
            @include desktop-large-bp {
                font-size: 1.8rem;
            }
        }
    }
    .fig-link{
        margin-top: 0.4rem;
        a.link-body:visited,  .link-body,   a:visited{
            margin: -0.25rem 0;
        }
        @at-root #{&}{
            @include phone-small-bp{
                font-size: 1.4rem;
            }
            @include phone-large-bp {
                font-size: 1.4rem;
            }
            @include tablet-small-bp {
            }
            @include tablet-large-bp {
            }
            @include desktop-small-bp {
            }
            @include desktop-medium-bp {
                font-size: 1.4rem;
            }
            @include desktop-large-bp {
                font-size: 1.8rem;
            }
        }
    }
    cite{
        font-style: normal;
    }
}
//-------------------------------------------
// -Prev/Next
// ------------------------------------------
.prev-next-container{
	p {
		padding: 0;
		margin: 1rem 0 1.8rem 0;
		  // word-wrap: break-word;
		  // hyphens: auto;
		@at-root #{&}{
			  @include phone-small-bp{
				  font-size: 3rem;
			  }
			  @include phone-large-bp {
				  font-size: 1.4rem;
			  }
			  @include tablet-small-bp {
				  font-size: 1.8rem;
			  }
			  @include desktop-large-bp {
				font-size: 3.4rem;
			  }
		  }
	  }
	  h2{
		font-size: 6rem;
		font-family: var(--neuepower-ultra);
		line-height: 1;
		@at-root #{&}{
			@include phone-small-bp{
				font-size: 4.3rem;
			}
			@include phone-large-bp {
				font-size: 3rem;
			}
			@include tablet-small-bp {
				font-size: 4rem;
			}
			@include tablet-large-bp {
				font-size: 5rem;
			}
			@include desktop-small-bp {
				font-size: 7rem;
			  }
			  @include desktop-medium-bp {
				font-size: 7.6rem;
			  }
			@include desktop-large-bp {
				font-size: 12rem;
			  }
		}
	  }
}