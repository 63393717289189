
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/placeholders";
@import "vendors/bootstrap";
@import "vendors/jquery-ui";
@import "vendors/fullpage.scss";
@import "base/reset";
@import "base/fonts";
@import "base/colours";
@import "base/typography";
@import "layout/navigation";
@import "layout/_side-navigation.scss";
@import "layout/grid";
@import "layout/header";
@import "layout/footer";
@import "layout/sidebar";
@import "layout/forms";
@import "components/buttons";
@import "components/carousel";
@import "components/cover";
@import "components/dropdown";
@import "components/loading-animation";
@import "components/inputs";
@import "pages/home";
@import "pages/contact";
@import "pages/about";
@import "pages/projects";
@import "pages/_case-studies.scss";

@import "themes/theme";
@import "themes/admin";

body {
  font-family: var(--avenirnext-regular);
  background:var(--black);
  color: var(--white);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 62.5% // sets base font size to equivalent of 10px for easy rem calculation
}


img, .size-auto {
  width: 100%;
  height: auto;
}

// .curser-pointer{
//   cursor: pointer;
// }
// .avoid-clicks {
//   pointer-events: none;
//   user-select: none;
// }

// .curser-pointer {
//   cursor: pointer;
// }

.text-align-centre{
  text-align: center;
}
.text-align-right{
  text-align: right;
}
.relative {
  position: relative;
}

// .break-word{
//   word-break: break-all;
//   word-break: break-word;
//   hyphens: auto;
// }

// .video-responsive {
//   width: 100%;
//   height: auto;
//   object-fit: contain;
// }

// a {
//   text-decoration: none;
//   color: aqua;
//   outline: none;
// }
a:visited {
  color: var(--aqua);
  text-decoration-color: var(--aqua);
}
a {
  color: var(--aqua);
  text-decoration-color: var(--aqua);
}