// ======================================================
// Index/Homepage
// ======================================================

.contact-container{
	.border-left{
		border-left: .2rem solid var(--white);
	}
}
.content-frame{
	// margin: 6rem 0 10vw 0; 
	padding: 5vw 0 10vw;
	grid-template-columns: repeat(12, 1fr);
	display: grid;
	position: relative;
	isolation: isolate;
	img{
		grid-column: 7/span 4;
		grid-row: 1/span 5;
		position: absolute;
		z-index: -1;
		display: grid;
	}
	.grid-item-span-9{
		@at-root #{&}{
			@include phone-small-bp {
				grid-column: span 12;
			}
			@include phone-large-bp {
				grid-column: span 11;
			}
			@include tablet-small-bp {
				grid-column: span 9;
			}
		}
	}
}
p.huge-text{
	font-size: 13.5vw;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	line-height: .85;
	font-family: var(--neuepower-ultra);
	grid-column: span 12;
  }
  p.subtitle-text{
	grid-column: span 10;
  }
.outlined-text{
	-webkit-text-fill-color: transparent; 
	position: absolute;
	&:nth-of-type(1){
		opacity: 0;
		animation: slide-in-out-1 linear 15s infinite;
	}
	&:nth-of-type(2){
		opacity: 0;
		animation: slide-in-out-2 linear 15s infinite;
	}
	&:nth-of-type(3){
		opacity: 0;
		animation: slide-in-out-3 linear 15s infinite;
	}
	&:nth-of-type(4){
		opacity: 0;
		animation: slide-in-out-4 linear 15s infinite;
	}
	&:nth-of-type(5){
		opacity: 0;
		animation: slide-in-out-5 linear 15s infinite;
	}
	@at-root #{&}{
		@include phone-small-bp {
			-webkit-text-stroke:  0.08rem var(--white);
		}
		@include phone-large-bp {
			// -webkit-text-stroke:  0.08rem var(--white);
			-webkit-text-stroke: 0.2rem var(--white);
		}
		@include tablet-small-bp {
			-webkit-text-stroke: 0.2rem var(--white);
		}
	}
  }
.text-animation-container{
	height: 11.5vw;
	position: relative;
}
@keyframes slide-in-out-1{
	0%{ opacity: 0;}
	10%{ opacity: 1;}
	20%{ opacity: 0;}
	30%{ opacity: 0;}
	40%{ opacity: 0;}
	50%{ opacity: 0;}
	60%{ opacity: 0;}
	70%{ opacity: 0;}
	80%{ opacity: 0;}
	90%{ opacity: 0;}
	100%{ opacity: 0;}
}

@keyframes slide-in-out-2{
	0%{ opacity: 0;}
	10%{ opacity: 0;}
	20%{ opacity: 0;}
	30%{ opacity: 1;}
	40%{ opacity: 0;}
	50%{ opacity: 0;}
	60%{ opacity: 0;}
	70%{ opacity: 0;}
	80%{ opacity: 0;}
	90%{ opacity: 0;}
	100%{ opacity: 0;}
}
@keyframes slide-in-out-3{
	0%{ opacity: 0;}
	10%{ opacity: 0;}
	20%{ opacity: 0;}
	30%{ opacity: 0;}
	40%{ opacity: 0;}
	50%{ opacity: 1;}
	60%{ opacity: 0;}
	70%{ opacity: 0;}
	80%{ opacity: 0;}
	90%{ opacity: 0;}
	100%{ opacity: 0;}
}
@keyframes slide-in-out-4{
	0%{ opacity: 0;}
	10%{ opacity: 0;}
	20%{ opacity: 0;}
	30%{ opacity: 0;}
	40%{ opacity: 0;}
	50%{ opacity: 0;}
	60%{ opacity: 0;}
	70%{ opacity: 7;}
	80%{ opacity: 0;}
	90%{ opacity: 0;}
	100%{ opacity: 0;}
}
@keyframes slide-in-out-5{
	0%{ opacity: 0;}
	10%{ opacity: 0;}
	20%{ opacity: 0;}
	30%{ opacity: 0;}
	40%{ opacity: 0;}
	50%{ opacity: 0;}
	60%{ opacity: 0;}
	70%{ opacity: 0;}
	80%{ opacity: 0;}
	90%{ opacity: 1;}
	100%{ opacity: 0;}
}
.main-section {
	height: 100vh;
}
.horizontal-text{
	transform: rotate(180deg);
    top: 0rem;
    writing-mode: tb-rl;
	text-align:right;
	text-transform: uppercase;
	line-height: .85;
	pointer-events: none;
	@at-root #{&}{
		@include phone-small-bp {
			font-size: 12vw;
		}
	}
}
.horizontal-text-container{display: grid;
	grid-template-columns: 2fr 4fr;
	.video-colour, video{
		margin: 12vw 0 4rem 0;
		transition: all 0.2s linear;
		filter: grayscale(100%) contrast(150%);
		&:hover{
			filter: grayscale(0%) contrast(100%);
		}
	}
	@at-root #{&}{
		@include phone-small-bp {
			grid-template-columns: 1fr 4fr;
		}
		@include phone-large-bp {
			grid-template-columns: 2fr 4fr;
		}
	}
}
.video-black-white.video-colour{
	filter: grayscale(0%) contrast(100%);
}
.text-stroke{
    -webkit-text-fill-color: transparent;
    @at-root #{&}{
		@include phone-small-bp {
			-webkit-text-stroke: 0.08rem var(--white);
		}
		@include phone-large-bp {
			-webkit-text-stroke: 0.08rem var(--white);
			// -webkit-text-stroke: 0.2rem var(--white);
		}
		@include tablet-small-bp {
			-webkit-text-stroke: 0.2rem var(--white);
		}
	}
}
// ======================================================
// 404 Page
// ======================================================
.video-responsive-background{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .video-background-container{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: black;
  }