// ======================================================
// ---Header
// 1. Logo
// 2. Progress Bar
// ======================================================

// ======================================================
// Logo
// ======================================================
.logo-container {
    position: fixed;
// position: absolute;
    height: 4rem;
    // width: calc(100vw - 4rem );
    display: grid;
    justify-content: start;
    z-index: 1999;
    padding: 1.5rem;
    top: 0;
    left: 0;
    // background: tomato;
    @at-root #{&}{
      @include phone-small-bp{
        padding: 1rem;
      }
      // @include phone-large-bp{
      //   justify-content: end;
      //   padding: 1rem 0;
      // }
      // @include tablet-small-bp {
      //   justify-content: start;
      //   padding: 1.5rem;
      // }
    }
}
.logo {
    width: 4rem;
    height: 4rem;
}
.abs{position: absolute;}
//-------------------------------------------
// Logo Glitch Effect
// ------------------------------------------
  @mixin svgGlitch($name, $intensity, $fillColor, $background, $fillHighlight1, $fillHighlight2, $width, $height, $top, $left) {
    $steps: $intensity;
    @at-root {
      @for $i from 1 through 2 {
        @keyframes #{$name}-anim-#{$i} {
          @for $i from 0 through $steps {
            $top: random(100);
            $bottom: random(101 - $top);
            #{percentage($i*(1/$steps))} {
              clip-path: inset(#{$top}% 0 #{$bottom}% 0);
            }
          }
        }
      }
    }
svg {
    position: absolute;
    top: 0+rem;
    left:0+rem;
    fill: $fillColor;
    background: $background;
    width: $width+rem;
    height: $height+rem;
}
svg:nth-child(2) {
    fill: $fillHighlight1;
    left: ($left - .1) + rem;
    animation: #{$name}-anim-1 9s infinite linear alternate-reverse;
}
svg:nth-child(3) {
    fill: $fillHighlight1;
    left: ($left + .1) + rem;
    animation: #{$name}-anim-2 12s infinite linear alternate-reverse;
}
svg:nth-child(4) {
  fill: $fillHighlight1;
  left: ($left + .1) + rem;
  animation: #{$name}-anim-2 12s infinite linear alternate-reverse;
}
svg:nth-child(5) {
  fill: $fillHighlight1;
  left: ($left + .1) + rem;
  animation: #{$name}-anim-2 12s infinite linear alternate-reverse;
}
}
.svg-glitch {
@include svgGlitch("example-four", 30, #ffffff, transparent,#ffffff, #ffffff, 3, 3, 0,0);
transition: transform .5s ease-in-out;
}
//-------------------------------------------
// Logo Hover
// ------------------------------------------
.svg-glitch:hover svg {
    animation: heartbeat .3s cubic-bezier(.12,.93,.83,.3) forwards ;
}
@keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
    }
    20% {
      -webkit-transform: scale(0.91);
    }
    32% {
      -webkit-transform: scale(0.98);
    }
    70% {
      -webkit-transform: scale(1.19);
    }
    100% {
      -webkit-transform: scale(1.15);
    }
}

// ======================================================
// Progress Bar
// ======================================================
#progress-bar {
  --scrollAmount: 0%;
  background-image: linear-gradient(120deg, var(--white) 0%, var(--white) 100%);
  width: var(--scrollAmount);
  height: .4rem;
  position: fixed;
  top: 0;
  z-index: 100;
}
