//======================================================
// Headers
//======================================================
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
 
  line-height: 1.5;

  font-family: var(--neuepower-ultra);
}
h1 {
  font-size: 14vw;
  // color: var(--aqua);
  line-height: 1;
  font-weight: unset;
}
h2 {
  font-size: 2rem;
  font-family:var(--avenirnext-bold);
  margin: 1.2rem 0 0 0;
  @at-root #{&}{
    @include desktop-large-bp {
      font-size: 3rem;
    }
  }
}
h3 {
  font-size: 2.4rem;
}
h4 {
  font-size: 3rem;
}
h5 {
  font-size: 1.25rem;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
h6 {
  font-size: 1rem;
}
.italic{
  font-style: italic;
}
h2.large-heading {
  @at-root #{&}{
    @include phone-small-bp{
        font-size: 2.4rem;
    }
    @include phone-large-bp {
        font-size: 3rem;
    }
    @include tablet-small-bp {
        font-size: 5rem;
    }
    @include tablet-large-bp {
        font-size: 7rem;
    }
    @include desktop-small-bp {
        font-size: 8rem;
    }
    @include desktop-medium-bp {
        font-size: 10rem;
    }
    @include desktop-large-bp {
        font-size: 14rem;
    }
}
}
// -------------------------------------------
// Responsive
// ------------------------------------------/

//======================================================
// Paragraphs and Lists
//======================================================
p {
  padding: 0;
  letter-spacing: 0.2px;
  // word-wrap: break-word;
  // hyphens: auto;
  @at-root #{&}{
		@include phone-small-bp{
			font-size: 1.8rem;
		}
		@include phone-large-bp {
			font-size: 2rem;
		}
		@include tablet-small-bp {
			font-size:2.8rem;
		}
		@include desktop-large-bp {
      font-size: 3.2rem;
		}
	}
}
.content p {
  padding: 0;
  margin: 0 0 1.8rem 0;
    // word-wrap: break-word;
    // hyphens: auto;
  @at-root #{&}{
		@include phone-small-bp{
			font-size: 1.4rem;
		}
		@include phone-large-bp {
			font-size: 1.4rem;
		}
		@include tablet-small-bp {
			font-size: 1.8rem;
		}
		@include desktop-large-bp {
      font-size: 2.4rem;
		}
	}
}
ul {
  margin: 0;
  padding: 0;
}
li{
  font-size: 1.8rem;

  @at-root #{&}{
		@include phone-small-bp{
			font-size: 1.4rem;
		}
		@include phone-large-bp {
			font-size: 1.4rem;
		}
		@include tablet-small-bp {
      font-size: 1.8rem;
		}
		@include desktop-large-bp {
      font-size: 2.4rem;
		}
	}
}



//======================================================
// Links
//======================================================
// .link-body, a:visited, a.link-body:visited{
.link-body a{
  color: var(--aqua);
  text-decoration: underline;
  text-decoration-color:var(--aqua);
  line-height: 1;
  transition: all 0.2s;
  // overflow: hidden;
  // margin: -0.5rem  0;
  // @at-root #{&}{
	// 	@include phone-small-bp{
	// 		margin:-0.4rem  0;
	// 	}
	// 	@include phone-large-bp {
	// 		margin:-0.4rem  0;
	// 	}
	// 	@include tablet-small-bp {
	// 		margin: -0.5rem  0;
	// 	}
	// 	@include desktop-large-bp {
  //     margin: -0.71rem 0;
	// 	}
	// }
  .fig-title &{
      margin:-0.6rem 0;
  }
  .fig-link & {
      margin:-0.46rem  0;
      @include phone-small-bp{
        margin:-0.25rem  0;
      }
      @include phone-large-bp {
        margin:-0.25rem  0;
      }
      @include tablet-small-bp {
        margin: -0.35rem 0;
      }
      @include desktop-large-bp {
        margin: -0.35rem 0;
      }
  }
  .tweet-container .fig-link & {
      margin:-0.46rem  0;
      @include phone-small-bp{
        margin:-0.25rem  0;
      }
      @include phone-large-bp {
        margin:-0.25rem  0;
      }
      @include tablet-small-bp {
        margin: -0.35rem 0;
      }
      @include desktop-large-bp {
        margin: -0.35rem 0;
      }
  }
  .fig-subtitle & {
      margin:-0.46rem  0;
      @include phone-small-bp{
        margin:-0.35rem  0;
      }
      @include phone-large-bp {
        margin:-0.35rem  0;
      }
      @include tablet-small-bp {
        margin: -0.45rem  0;
      }
      @include desktop-large-bp {
        margin: -0.45rem 0;
      }
  }
  &:hover {
    text-decoration-color:var(--white);
  }
}
//======================================================
// Responsive Text
//======================================================

