// --------------------------------------
// Footer
// --------------------------------------
.footer-content{
	border-top: 0.2rem solid var(--white);
	display: flex;
	justify-content: space-between;
	margin-top: var(--padding-value);
}
.footer-menu{
	list-style: none;
	display: flex;
	gap: 2rem;
	li {
		font-size: 1.6rem;
	}
}
.social-links{
 	text-decoration: none;
	display: flex;
	gap: 2rem;
}

@include phone-small-bp {
	.footer-content{
		display: grid;
	}
  }
  @include phone-large-bp {
	.footer-content{
		display: grid;
	}
  }
  @include tablet-small-bp {
	.footer-content{
		display: flex;
	}
  }