.case-studies,
.contact-page,
.about-page{
    .video-responsive{
        margin-top: -12vw;
        @at-root #{&} {
            @include phone-small-bp{
                margin-top: -28vw;
                padding: 10vw;
                box-sizing: border-box;
            }
        }
    }
    
    .hero-header{
        margin-top: -10vw;
        text-align: center;
        z-index: 1;
        @at-root #{&} {
            @include phone-small-bp{
                margin-top: -26vw;
        }
    }
}
    h2{
        padding: 2rem 0; 
    }
    .border-left{
       
        @at-root #{&} {
            @include phone-small-bp{
                padding: 0;
            }
            @include phone-large-bp{
                padding: 0;
            }
            @include tablet-small-bp{
                padding: 0 6rem;
        }
    }
}
}



.contact-form{
    xdisplay: grid;
    grid-template-columns: 2fr 2fr;
    @at-root #{&} {
        @include phone-small-bp{
            grid-template-columns: 1fr;
        }
        @include phone-large-bp{
            grid-template-columns: 1fr;
        }
        @include tablet-small-bp{
            grid-template-columns: 2fr 2fr;
    }
}
}




// .case-studies{
//     .bg-gif{
//         @at-root #{&} {
//             @include phone-small-bp{
//                 grid-column: span 12;
//             }
//         }
//     }
// }