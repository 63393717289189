input,textarea{
    width: 100%;
    background: var(--charcoal-light);
    border: 0 ;
    border-bottom: 0.1rem solid var(--white);
    color: var(--white);
    font-size: 2.4rem;
    // min-height:  6rem;
    margin-bottom: 6rem;
    padding: 1.6rem 2.4rem;
    border-radius: 0.2rem;
&:focus{
    outline: none;
    background: var(--charcoal);
}
}
label{
    font-size: 2.4rem;
}